export const environment = {
  production: false,
  apiUrl: 'https://api.stage.gamers.ba/api',
  cdnURL: 'https://cdn.stage.gamers.ba',
  pusher: {
    key: 'f8a5af8df297d9a6d7e7',
    secret: '9bc33a3181d244d838f9',
    cluster: 'eu',
    authEndpoint: 'https://api.stage.gamers.ba/broadcasting/auth',
  },
};
